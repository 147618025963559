class Header {
  constructor() {
    this.video = document.querySelector('#theVideo');
    if(this.video){
      this.video.playbackRate = 0.5;
    }

  }
}

new Header();
